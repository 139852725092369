<template>
  <div class="alert-content-item" v-if="props.itemData && props.itemType">
    <div class="item-title">
      <div class="item-title-main" v-if="props.itemType === 1">
        【<span v-if="props.itemData.warn_level">{{
          props.itemData.warn_level
        }}</span
        >{{ props.itemData?.warn_type }}】{{ props.itemData?.warn_content }}
      </div>
      <div class="item-title-main" v-else>
        【{{ props.itemData?.notice_type }}】{{ props.itemData?.title }}
      </div>
      <div class="item-title-others">
        <div class="item-title-other">
          <div class="other-name" v-if="props.itemType === 1">
            <div class="obj">
              {{ $t('components.popContentMain.005741-0') }}
            </div>
            <div class="name">{{ props.itemData?.current_name }}</div>
          </div>
          <div class="other-name" v-else>
            <div class="obj">
              {{ $t('components.popContentMain.005741-1') }}
            </div>
            <div class="name">{{ props.itemData?.user_name }}</div>
          </div>
          <img
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/18a81fce1467039fee612d08de2e105a.png"
          />
          <div class="other-time">
            <div class="title">
              {{ $t('components.popContentMain.005741-2') }}
            </div>
            <div class="time">
              {{ parseTime(props.itemData?.created_at, '{m}-{d} {h}:{i}') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert-content-detail" v-loading="loading">
      <div
        class="detail-content"
        v-if="props.itemData?.source_form && props.itemType === 1"
      >
        <div style="height: 550px">
          <PcParserNew
            v-if="formDesignData"
            :form-data="formDesignData"
            :displayData="displayData"
            :editFields="{}"
            :disabled="disabled"
            :moduleUuid="formUUID"
            :formMode="requestType"
            :nowArchiType="archi_type"
          />
        </div>
      </div>
      <div
        v-else-if="!props.itemData?.source_form && props.itemType === 1"
        class="noData"
      >
        {{ $t('news.Index.953365-2') }}
      </div>
      <div v-else class="detail-content-sys">
        <div class="editor-cont" @click="onClick">
          <Editor
            :init="init"
            v-model="props.itemData.text"
            style="line-height: 1.5; overflow-y: auto; overflow-x: hidden"
            disabled
          />
          <fileUpload
            style="padding: 10px 0"
            v-if="props.itemData?.accessory?.length"
            :disabled="true"
            :files="props.itemData?.accessory"
          />
        </div>
      </div>
    </div>
    <el-image-viewer
      v-if="dialogVisible"
      :z-index="3010"
      :url-list="imageArr"
      :on-close="onCloseView"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import PcParserNew from '@/custom-component/form/newParser/PcParserNew.vue';
import { defineProps, ref, onMounted, watch } from 'vue';
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import Editor from '@tinymce/tinymce-vue';
import fileUpload from '../../../components/fileUpload/newFiles/editorUpoad.vue';
import { parseTime } from '@/utils/util';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue';

import 'tinymce/themes/silver'; // 编辑器主题，不引入则报错
import 'tinymce/icons/default'; // 引入编辑器图标icon，不引入则不显示对应图标
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.inline.min.css';
import 'tinymce/skins/ui/oxide/skin.shadowdom.min.css';
import '@/assets/zh_CN';
// 引入编辑器插件（基本免费插件都在这儿了）
import 'tinymce/plugins/advlist'; // 高级列表
import 'tinymce/plugins/anchor'; // 锚点
import 'tinymce/plugins/autolink'; // 自动链接
import 'tinymce/plugins/autoresize'; // 编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave'; // 自动存稿
import 'tinymce/plugins/charmap'; // 特殊字符
import 'tinymce/plugins/code'; // 编辑源码
import 'tinymce/plugins/codesample'; // 代码示例
import 'tinymce/plugins/directionality'; // 文字方向
import 'tinymce/plugins/emoticons'; // 表情
import 'tinymce/plugins/fullpage'; // 文档属性
import 'tinymce/plugins/fullscreen'; // 全屏
import 'tinymce/plugins/help'; // 帮助
import 'tinymce/plugins/hr'; // 水平分割线
import 'tinymce/plugins/image'; // 插入编辑图片
import 'tinymce/plugins/importcss'; // 引入css
import 'tinymce/plugins/insertdatetime'; // 插入日期时间
import 'tinymce/plugins/link'; // 超链接
import 'tinymce/plugins/lists'; // 列表插件
import 'tinymce/plugins/media'; // 插入编辑媒体
import 'tinymce/plugins/nonbreaking'; // 插入不间断空格
import 'tinymce/plugins/pagebreak'; // 插入分页符
import 'tinymce/plugins/paste'; // 粘贴插件
import 'tinymce/plugins/preview'; // 预览
import 'tinymce/plugins/print'; // 打印
import 'tinymce/plugins/quickbars'; // 快速工具栏
import 'tinymce/plugins/save'; // 保存
import 'tinymce/plugins/searchreplace'; // 查找替换
import 'tinymce/plugins/tabfocus'; // 切入切出，按tab键切出编辑器，切入页面其他输入框中
import 'tinymce/plugins/table'; // 表格
import 'tinymce/plugins/template'; // 内容模板
import 'tinymce/plugins/textcolor'; // 文字颜色
import 'tinymce/plugins/textpattern'; // 快速排版
import 'tinymce/plugins/toc'; // 目录生成器
import 'tinymce/plugins/visualblocks'; // 显示元素范围
import 'tinymce/plugins/visualchars'; // 显示不可见字符
import 'tinymce/plugins/wordcount'; // 字数统计

const formUUID = ref();
const showDialog = ref();
const loading = ref(false);
const needFlow = ref();
const formDesignData = ref(null);
const newFormFlow = ref();
const displayData = ref();
const editFields = ref();
const prepared = ref();
const requestType = ref('detail');
const archi_type = ref('');
const dialogVisible = ref(false);
const imageArr = ref([]);

const props = defineProps({
  itemData: {
    type: Object,
    default: () => {},
  },
  itemType: {
    type: Number,
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const init = {
  language: 'zh_CN',
  menubar: false, // 禁用菜单栏
  toolbar: '',
  height: '100%',
  branding: false,
  statusbar: false,
  inline: true, //开启内联模式
};
onMounted(() => {
  if (props.itemData) {
    getForms(props);
  }
});
watch(
  () => props.itemData,
  () => {
    getForms(props);
  }
);

const onClick = (e) => {
  if (e.target.src) {
    imageArr.value = [e.target.src];
    dialogVisible.value = true;
  }
};

const onCloseView = () => {
  dialogVisible.value = false;
};

const getForms = (props) => {
  if (props.itemData?.source_form) {
    formUUID.value = props.itemData?.source_form;
    showDialog.value = true;
    loading.value = true;
    transFieldsUUid(formUUID.value)
      .then((res) => {
        if (res.data.code == 200) {
          needFlow.value = res.data.data.need_flow;
          // this.needParentFlow = !!res.data.data.need_parent_flow;
          // 编辑
          // 获取已经有的数据进行绑定
          let config = res.data.data;
          let ruuids = [];
          for (let i = 0; i < config.fields.length; i++) {
            let item = config.fields[i];
            if (item.__config__.tagIcon == 'form') {
              ruuids.push({ relationship_uuid: item.__vModel__ });
            }
          }
          dataInterface({
            data_id: props.itemData?.source_data_id,
            object_uuid: props.itemData?.source_object_uuid,
            view_uuid: props.itemData?.source_data_uuid,
            ruuids,
            __method_name__: 'dataInfo',
            __now_archi_type: props.itemData?.archi_type,
          }).then((res1) => {
            let data = res1.data.data;
            formDesignData.value = res.data.data;
            if (data?.recover?.cells) {
              newFormFlow.value = true; // 新版流程表单
            }

            displayData.value = data;
            editFields.value = data?.editFields || {};
            loading.value = false;
            prepared.value = true;
            showDialog.value = true;
          });
        }
      })
      .catch((err) => {
        loading.value = false;
      });
  }
};
</script>
<style>
.editor-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editor-cont .mce-content-body img {
  width: 100%;
}
</style>
<style lang="less" scoped>
.alert-content-item {
  display: flex;
  width: 560px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  height: auto;

  .alert-content-detail {
    display: flex;
    margin: 0px var(--spacing-16, 32px);
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--overall-surface-variant, #f2f5fa);
    height: calc(100% - 122px);
    .detail-content {
      padding: 10px 15px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: var(--Radio-6, 6px);
      overflow-x: hidden;
      max-width: 496px;
      word-wrap: break-word;
      word-break: break-all;
    }
    .detail-content-sys {
      padding: 10px 15px;
      flex: 1 0 0;
      overflow-x: hidden;
      word-wrap: break-word;
      word-break: break-all;
      box-sizing: border-box;
      height: 100%;
      img {
        width: 100%;
      }
    }
    .noData {
      height: 550px;
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .item-title {
    display: flex;
    padding: var(--spacing-12, 24px) var(--spacing-10, 20px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .item-title-main {
      color: var(--text-on-surface-primary, #181b22);
      font-family: 'MiSans VF';
      font-size: 22px;
      font-style: normal;
      padding-left: 12px;
      font-weight: 380;
      line-height: 30px;
      /* 136.364% */
    }
  }

  .item-title-others {
    display: flex;
    padding: 0px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 32px;

    .item-title-other {
      display: flex;
      height: var(--button-list-field-medium, 32px);
      padding: 6px 12px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: var(--Radio-4, 4px);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        var(--overall-surface-variant, #f2f5fa);
    }

    .other-name {
      display: flex;
      align-items: center;

      .obj {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: var(--text-on-surface-tertiary, #707786);
        text-overflow: ellipsis;
        font-family: 'MiSans VF';
        font-size: 13px;
        font-style: normal;
        font-weight: 330;
        line-height: 20px;
        /* 153.846% */
      }

      .name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: #424751;
        text-overflow: ellipsis;
        font-family: 'MiSans VF';
        font-size: 13px;
        font-style: normal;
        font-weight: 330;
        line-height: 20px;
        /* 153.846% */
      }
    }

    .other-time {
      display: flex;
      align-items: center;

      .title {
        color: var(--text-on-surface-tertiary, #707786);
        // width: 39px;
        text-align: right;
        font-family: 'MiSans VF';
        font-size: 13px;
        font-style: normal;
        font-weight: 330;
        line-height: 20px;
        /* 153.846% */
      }

      .time {
        color: #424751;
        font-family: 'MiSans VF';
        font-size: 13px;
        font-style: normal;
        font-weight: 330;
        line-height: 20px;
        /* 153.846% */
      }
    }
  }
}
</style>
