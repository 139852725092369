<template>
	<div class="right-sider" v-if="props.itemType === 1 && props.itemData">
		<div>
			<div class="deal-content" v-if="is1Read === 0" @click="switchReadStatus()">
				<div class="noDeal">
					<i class="iconfont iconyueduzhuangtai"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-0') }}</span>
			</div>
			<div v-else-if="is1Read === 1" class="deal-content">
				<div class="isDeal">
					<i class="iconfont iconyueduzhuangtai" style="color: #387ffc"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-1') }}</span>
			</div>
		</div>
		<div>
			<div v-if="is1Deal === 2" class="deal-content" @click="toDeal()">
				<div class="noDeal">
					<i class="iconfont icondianjichuli"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-2') }}</span>
			</div>
			<div v-else-if="is1Deal === 3" class="deal-content">
				<div class="isDeal">
					<i class="iconfont icondianjichuli" style="color: #387ffc"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-3') }}</span>
			</div>
		</div>
	</div>

	<div class="right-sider" v-else>
		<div>
			<div v-if="is2Read === 0" class="deal-content" @click="switchReadStatus()">
				<div class="noDeal">
					<i class="iconfont iconyueduzhuangtai"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-0') }}</span>
			</div>
			<div v-else-if="is2Read === 1" class="deal-content">
				<div class="isDeal">
					<i class="iconfont iconyueduzhuangtai" style="color: #387ffc"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-1') }}</span>
			</div>
		</div>
		<div>
			<div v-if="is2Deal === 2" class="deal-content" @click="toRep()">
				<div class="noDeal">
					<i class="iconfont icondianjichuli"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-4') }}</span>
			</div>
			<div v-else-if="is2Deal === 3" class="deal-content">
				<div class="isDeal">
					<i class="iconfont icondianjichuli" style="color: #387ffc"></i>
				</div>
				<span>{{ $t('components.popContentSider.441255-5') }}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, watch, inject, defineEmits } from 'vue';
import { dataInterface } from '@/apis/data';

const is1Read = ref(-1);
const is1Deal = ref(-1);
const is2Read = ref(-1);
const is2Deal = ref(-1);
const isZoom = ref(false);

const props = defineProps({
	tochange: {
		type: Function,
		default: () => {}
	},
	itemData: {
		type: Object,
		default: () => {}
	},
	itemType: {
		type: Number,
		default: 1
	},
	switchType: {
		type: Number,
		default: 1
	},
	isZoom: {
		type: Boolean,
		default: false
	},
	siderPlush: {
		type: Boolean,
		default: false
	},
	isReply: {
		type: Boolean,
		default: false
	}
});

if (props.itemType === 1 && props.itemData) {
	is1Read.value = +props.itemData.is_read; //s
	is1Deal.value = +props.itemData.need_deal; //n
} else if (props.itemType === 2 && props.itemData) {
	is2Read.value = +props.itemData.is_read; //s
	is2Deal.value = +props.itemData.is_reply; //s
}

watch(
	() => props.itemData,
	(New, Old) => {
		if (props.switchType === 2 && props.itemType === 2) {
			emits('on-handle', true);
		}
		if (props.itemType === 1 && props.itemData) {
			is1Read.value = +props.itemData.is_read; //s
			is1Deal.value = +props.itemData.need_deal; //n
		} else if (props.itemType === 2 && props.itemData) {
			is2Read.value = +props.itemData.is_read; //s
			is2Deal.value = +props.itemData.is_reply; //s
		}
	},
	{ deep: true }
);

watch(
	() => props.isZoom,
	(New) => {
		isZoom.value = New;
	}
);

watch(
	() => props.siderPlush,
	() => {
		is1Read.value = 1;
		is2Read.value = 1;
	}
);
watch(
	() => props.isReply,
	() => {
		is2Deal.value = 3;
		toUpdate(false, true);
	}
);

const emits = defineEmits(['toSysItemDataIsUpdate', 'toAlertItemDataIsUpdate', 'on-handle']);

const toUpdate = inject(['toUpdate']);

const switchReadStatus = () => {
	const id = JSON.parse(localStorage.getItem('userInfo')).id;
	if (props.itemType === 1) {
		dataInterface({
			__method_name__: 'updateData', //该参数值取固定值
			object_uuid: 'object635201835e212', //该参数值取固定值
			view_uuid: 'view66ab6547c6558', //该参数值取固定值
			data_id: props.itemData.id, //该参数取预警列表返回的id值
			read_type: '1' //该参数值取固定值
		}).then((res) => {
			if (res.data.code === 200) {
				dataInterface({
					warn_information_list_id: props.itemData.id, //预警列表接口返回的id值
					alert_people: id, //当前登录用户id
					object_uuid: 'object66ab221ff39d8', //该参数值取固定值
					__method_name__: 'createData', //该参数值取固定值
					transcode: 0 //该参数值取固定值
				}).then((res1) => {
					if (res1.data.code === 200) {
						is1Read.value = 1;
						emits('toAlertItemDataIsUpdate', true);
						toUpdate(true, false);
					}
				});
			}
		});
	} else {
		if (props.itemData.announcement_source === 1) {
			dataInterface({
				is_read: '1',
				object_uuid: 'object62a938469ffdd',
				__method_name__: 'updateData',
				data_id: props.itemData.announcements_user_id,
				transcode: 0
			}).then((res) => {
				if (res.data.code === 200) {
					is2Read.value = +res.data.data.is_read;
					emits('toSysItemDataIsUpdate', true);
					// toUpdate(false, true)
				}
			});
		} else {
			const section_id = JSON.parse(localStorage.getItem('targetArchi')).sectionId;
			const userId = JSON.parse(localStorage.getItem('userInfo')).id;
			dataInterface({
				section_id: section_id, //取当前登录用户所在部门id
				yong_id: userId, //取当前登录用户id
				post_object_id_use: props.itemData.user_id, //取通知公告列表和查看更多数据时接口返回的uer_id值
				announcements_id: props.itemData.id, //取通知公告列表和查看更多数据时接口返回的id值
				is_read: 1, //该参数值取固定值
				object_uuid: 'object62a938469ffdd', //该参数值取固定值
				__method_name__: 'createData', //该参数值取固定值
				transcode: 0 //该参数值取固定值
			}).then((res) => {
				if (res.data.code === 200) {
					is2Read.value = +res.data.data.is_read;
					emits('toSysItemDataIsUpdate', true);
					// toUpdate(false, true)
				}
			});
		}
	}
};

const tochange = () => {
	emits('on-handle');
};

const toDeal = () => {
	tochange();
};

const toRep = () => {
	tochange();
};
</script>

<style lang="less" scoped>
.right-sider {
	display: flex;
	padding: 48px var(--spacing-16, 32px) 48px 0px;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-16, 32px);
	box-sizing: border-box;

	.deal-content {
		display: flex;
		width: 64px;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		background: #fff;

		span {
			align-self: stretch;
			color: var(--text-on-surface-tertiary, #707786);
			text-align: center;
			font-family: 'MiSans VF';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			/* 150% */
		}

		.isDeal {
			display: flex;
			width: 48px;
			height: 48px;
			justify-content: center;
			align-items: center;
			border-radius: var(--Radio-999, 999px);
			background: var(--overall-surface-alt-1, #f2f7ff);
		}

		.noDeal {
			border-radius: var(--Radio-999, 999px);
			background: var(--overall-surface-variant, #f2f5fa);
			display: flex;
			width: 48px;
			height: 48px;
			justify-content: center;
			align-items: center;
		}
		.noDeal:hover {
			background: var(--overall-surface-alt-1, #f2f7ff);
			& i {
				color: #387ffc;
			}
		}
	}
}
</style>
